"use client"

import React, { createContext, useEffect, useMemo } from "react"

import { hexToOklch } from "@supernovaio/dm/src/utils/color"
import * as colorConfig from "@supernovaio/dm/themes/darkmatter/color.config"
import { SelectedTheme } from "@supernovaio/dm/themes/darkmatter/themePresetsDefinition"

import { ThemeType } from "../types/theme"
import {
  adjustColors,
  adjustColorsSemanticAndDecorative,
} from "../utils/ThemeGeneratorCalculations"

// Type definitions
export type ThemeGeneratorValue = {
  backgroundColor: string
  contrast: number
  accentColor: string
  secondaryContrast: number
  secondaryBackgroundColor: string
  isSecondaryEnabled: boolean
  selectedPreset: string
  luminanceBackgroundColor: string
  isEditorWhite?: boolean
}

type ThemeMode = "light" | "dark" | "editor"
type ThemeRule = {
  token: string
  value: string
}
type GroupedRules = {
  [Mode in ThemeMode]?: ThemeRule[]
}

const getThemeSelector = (type: ThemeType): ThemeMode => {
  switch (type) {
    case "user-defined":
      return "light"
    case "always-dark":
      return "dark"
    case "editor":
      return "editor"
    default:
      return "light" // Providing a default case
  }
}

const disableTransitions = () => {
  document.documentElement.classList.add("force-disable-transitions")
}

const enableTransitions = () => {
  // Force reflow
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  document.documentElement.offsetHeight
  document.documentElement.classList.remove("force-disable-transitions")
}

// There are some user defined colors so we need to update style sheets
// We cannot just statically define css theme colors
const updateStyleSheet = (groupedRules: GroupedRules, type: ThemeType) => {
  // Disable transitions when switching to do immediate effect of changing theme
  disableTransitions()

  const cssContent = Object.entries(groupedRules)
    .map(([mode, rules]) => {
      const declarations = rules
        .map(({ token, value }) => `${token}: ${value}`)
        .join(";\n  ")
      return `[data-rs-theme="darkmatter"][data-rs-color-mode="${mode}"] {\n  ${declarations}\n}`
    })
    .join("\n\n")

  let styleElement = document.querySelector(
    `style[data-theme-generator="${type}"]`
  ) as HTMLStyleElement

  if (!styleElement) {
    styleElement = document.createElement("style")
    styleElement.setAttribute("data-theme-generator", type)
    document.head.appendChild(styleElement)
  }

  styleElement.textContent = cssContent

  // Delay enabling transitions for next frames
  requestAnimationFrame(() => {
    enableTransitions()
  })
}

export const DMThemeGenerator = createContext<ThemeGeneratorValue | undefined>(
  undefined
)

type DMThemeProviderGeneratedProps = {
  children: React.ReactNode
  theme: SelectedTheme
}

// Move these helper functions and the theme generation logic outside the component
const generateThemeAndApplyCSS = (params: {
  backgroundColor: string
  secondaryBackgroundColor: string
  editorColor: string
  contrast: number
  accentColor: string
}) => {
  const {
    backgroundColor,
    secondaryBackgroundColor,
    editorColor,
    contrast,
    accentColor,
  } = params

  const generateRulesForType = (type: ThemeType, color: string) => {
    const mode = getThemeSelector(type)
    const rules: GroupedRules = {
      [mode]: [],
    }

    const addBaseRules = (
      colors: Array<{ token: string; light: number; dark: number }>,
      selector: string
    ) => {
      colors.forEach(({ token, light, dark }) => {
        const adjustedValue = adjustColors(
          selector,
          color,
          contrast,
          light,
          dark
        )
        rules[mode]?.push({
          token,
          value: adjustedValue,
        })
      })
    }

    const addSemanticAndDecorativeRules = (
      colors: Array<{
        token: string
        light: number
        dark: number
        hex: string
      }>,
      selector: string
    ) => {
      colors.forEach(({ token, light, dark, hex }) => {
        const adjustedValue = adjustColorsSemanticAndDecorative(
          selector,
          color,
          contrast,
          light,
          dark,
          hex,
          accentColor
        )
        rules[mode]?.push({
          token,
          value: adjustedValue,
        })
      })
    }

    addBaseRules(colorConfig.baseColors, "base")
    addBaseRules(colorConfig.textColors, "text")
    addBaseRules(colorConfig.borderColors, "border")
    addBaseRules(colorConfig.transparentColors, "transparent")
    addBaseRules(colorConfig.blurColors, "blur")

    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsBackground,
      "background"
    )
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsForeground,
      "foreground"
    )
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsForegroundFaded,
      "foreground-faded"
    )
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsBackgroundFaded,
      "background-faded"
    )
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsBackgroundHighlighted,
      "background-highlighted"
    )
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsOnBackground,
      "on-background"
    )
    addSemanticAndDecorativeRules(colorConfig.semanticColorsBorder, "border")
    addSemanticAndDecorativeRules(
      colorConfig.semanticColorsBorderFaded,
      "border-faded"
    )
    addSemanticAndDecorativeRules(
      colorConfig.decorativeColorsBackground,
      "background-faded"
    )
    addSemanticAndDecorativeRules(
      colorConfig.decorativeColorsForeground,
      "foreground"
    )
    addSemanticAndDecorativeRules(
      colorConfig.decorativeColorsBorder,
      "border-faded"
    )

    return rules
  }

  const userDefinedRules = generateRulesForType("user-defined", backgroundColor)
  const alwaysDarkRules = generateRulesForType(
    "always-dark",
    secondaryBackgroundColor
  )
  const editorRules = generateRulesForType("editor", editorColor)

  updateStyleSheet(userDefinedRules, "user-defined")
  updateStyleSheet(alwaysDarkRules, "always-dark")
  updateStyleSheet(editorRules, "editor")
}

export function DMThemeGeneratedProvider({
  children,
  theme,
}: DMThemeProviderGeneratedProps) {
  const {
    contrast,
    backgroundColor,
    accentColor,
    secondaryContrast,
    secondaryBackgroundColor,
    isSecondaryEnabled,
    preset: selectedPreset,
  } = theme
  const { isEditorWhite } = theme

  const luminanceBackgroundColor = useMemo(() => {
    const { l } = hexToOklch(backgroundColor)
    return l < 0.62 ? "dark" : "light"
  }, [backgroundColor])

  useEffect(() => {
    generateThemeAndApplyCSS({
      backgroundColor,
      secondaryBackgroundColor:
        luminanceBackgroundColor === "light"
          ? secondaryBackgroundColor
          : backgroundColor,
      editorColor: isEditorWhite ? "#FFFFFF" : backgroundColor,
      contrast,
      accentColor,
    })
  }, [
    accentColor,
    backgroundColor,
    contrast,
    isEditorWhite,
    luminanceBackgroundColor,
    secondaryBackgroundColor,
  ])

  const value = useMemo<ThemeGeneratorValue>(() => {
    return {
      backgroundColor,
      contrast,
      accentColor,
      secondaryContrast,
      secondaryBackgroundColor,
      isSecondaryEnabled,
      selectedPreset,
      luminanceBackgroundColor,
      isEditorWhite,
    }
  }, [
    backgroundColor,
    contrast,
    accentColor,
    secondaryContrast,
    secondaryBackgroundColor,
    isSecondaryEnabled,
    selectedPreset,
    isEditorWhite,
    luminanceBackgroundColor,
  ])

  return (
    <DMThemeGenerator.Provider value={value}>
      {children}
    </DMThemeGenerator.Provider>
  )
}
