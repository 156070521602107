import { hexToOklch } from "@supernovaio/dm/src/utils/color"

/**
 * Adjusts colors based on selector type, base color, contrast level and light/dark adjustments
 * @param selector - Type of color adjustment to apply (base, transparent, text, border, blur)
 * @param color - Base color to adjust in hex format
 * @param contrast - Contrast level (0-100)
 * @param lightAdjustment - Adjustment amount for light theme
 * @param darkAdjustment - Adjustment amount for dark theme
 * @returns Adjusted color value in hex format
 */

const luminanceThreshold = 0.62

export const adjustColors = (
  selector: string,
  color: string,
  contrast: number,
  lightAdjustment: number,
  darkAdjustment: number
): string => {
  // Convert hex color to OKLCH color space
  const oklchColor = hexToOklch(color)
  const luminance = oklchColor.l // Get luminance value (0-1)

  // Determine adjustment based on if color is light or dark
  const adjustment =
    luminance > luminanceThreshold ? lightAdjustment : darkAdjustment
  // Scale contrast to 0-1 range
  const contrastModifier = contrast * 0.01

  // Initialize OKLCH components
  let newL = oklchColor.l // Luminance
  let newC = oklchColor.c // Chroma
  let newH = oklchColor.h // Hue
  let newO = 1 // Opacity

  switch (selector) {
    case "base":
      {
        // Calculate luminance adjustment based on contrast
        const adjustmentComputed = adjustment * contrastModifier

        // Special case - if very light color and no adjustment, make pure white
        if (newL > 0.975 && adjustmentComputed === 0) {
          newL = 1
          newC = 0
        }

        // Apply different adjustment amounts for dark vs light colors
        if (luminance < luminanceThreshold) {
          newL += adjustmentComputed
        } else {
          newL += adjustmentComputed * 0.4 // Reduced adjustment for light colors
        }
        break
      }
      break

    case "transparent":
      {
        // Larger adjustment for transparent colors
        const adjustmentComputed = adjustment * contrastModifier * 1.5

        if (adjustment > 0) {
          newL += 0.5 // Increase luminance
          newO = adjustmentComputed // Set opacity based on adjustment
        } else {
          newL -= 0.5 // Decrease luminance
          newO = Math.abs(adjustmentComputed) // Set opacity based on absolute adjustment
        }
      }
      break

    case "text":
      {
        // Reduced contrast for text colors
        const contrastModifierText = contrastModifier * 0.7
        // Invert adjustment direction based on base color luminance
        const adjustmentComputed =
          luminance > luminanceThreshold
            ? adjustment - contrastModifierText // on light background
            : adjustment + contrastModifierText
        newL += adjustmentComputed
        newC = 0.04 // This adds a slight tint to the text color
      }
      break

    case "border":
      {
        // Larger adjustment for borders
        const adjustmentComputed = adjustment * contrastModifier * 1.5

        if (adjustment > 0) {
          newL = 1 // White border
          newO = adjustmentComputed // Opacity based on adjustment
        } else {
          newL = 0 // Black border
          newO = Math.abs(adjustmentComputed) // Opacity based on absolute adjustment
        }
      }
      break

    case "blur":
      {
        // Standard contrast-based adjustment
        const adjustmentComputed = adjustment * contrastModifier * 0.5
        newL += adjustmentComputed
        // Different opacity for light vs dark colors
        newO = luminance > luminanceThreshold ? 0.92 : 0.95

        // Special case for very light colors
        if (newL > 0.975 && adjustmentComputed === 0) {
          newL = 1
          newC = 0
        }
      }
      break

    default:
      break
  }

  // Clamp luminance between 0-1
  if (newL >= 1) newL = 1
  if (newL <= 0) newL = 0
  if (!newH) newH = 0

  // Return final color in OKLCH format with opacity
  return `oklch(${newL} ${newC} ${newH} / ${newO})`
}

/**
 * Adjusts semantic and decorative colors based on various parameters
 * @param selector - The type of color adjustment to apply (background, foreground, etc)
 * @param color - Base color to adjust from
 * @param contrast - Contrast level (0-100) that affects adjustment intensity
 * @param lightAdjustment - Amount to adjust for light themes
 * @param darkAdjustment - Amount to adjust for dark themes
 * @param hex - Custom hex color or 'custom' to use colorAccent
 * @param colorAccent - Accent color to use when hex is 'custom'
 * @returns An OKLCH color string with adjusted luminance, chroma, hue and opacity
 */
export const adjustColorsSemanticAndDecorative = (
  selector: string,
  color: string,
  contrast: number,
  lightAdjustment: number,
  darkAdjustment: number,
  hex: string,
  colorAccent: string
): string => {
  // Use either the provided hex color or the accent color if hex is 'custom'
  const passedColor = hex === "custom" ? colorAccent : hex

  // Convert colors to OKLCH format for easier manipulation
  const oklchColorAccent = hexToOklch(passedColor)
  const luminanceAccent = oklchColorAccent.l
  const oklchColor = hexToOklch(color)
  const luminance = oklchColor.l

  // Scale contrast to 0-1 range and determine adjustment based on luminance
  const contrastModifier = contrast * 0.01
  const adjustment =
    luminance > luminanceThreshold ? lightAdjustment : darkAdjustment

  // Start with accent color values as base
  let newL = oklchColorAccent.l // Luminance
  let newC = oklchColorAccent.c // Chroma (saturation)
  let newH = oklchColorAccent.h // Hue
  let newO = 1 // Opacity

  switch (selector) {
    case "background-faded": {
      // Creates a faded background effect with opacity based on contrast
      const adjustmentComputedFaded = contrastModifier * 3
      if (luminance < luminanceThreshold) {
        // Dark theme - slightly transparent dark background
        newO = 0.2 * adjustmentComputedFaded + adjustment
        newL = 0.7
      } else {
        // Light theme - slightly transparent light background
        newO = 0.2 * adjustmentComputedFaded + adjustment
        newL = 0.8
      }
      break
    }

    case "background-highlighted": {
      // Creates a subtle highlight effect by slightly darkening
      const adjustmentComputedFaded = contrastModifier * 0.1
      newL -= 0.05 + adjustmentComputedFaded
      break
    }

    case "foreground": {
      // Adjusts foreground color based on background luminance
      const adjustmentComputedForeground = contrastModifier * 0.5
      if (luminance < luminanceThreshold) {
        // Dark theme - lighter foreground
        newL = 0.7 + adjustmentComputedForeground + adjustment * 0.5
      } else {
        // Light theme - darker foreground
        newL = 0.6 - adjustmentComputedForeground - adjustment * 0.5
      }
      break
    }

    case "foreground-faded": {
      // Adjusts foreground color based on background luminance
      const adjustmentComputedForeground = contrastModifier * 0.5
      if (luminance < luminanceThreshold) {
        // Dark theme - lighter foreground
        newL = 0.7 + adjustmentComputedForeground + adjustment * 0.5
      } else {
        // Light theme - darker foreground
        newL = 0.65 - adjustmentComputedForeground - adjustment * 0.5
        newC -= 0.05
      }
      break
    }

    case "on-background": {
      // Creates high contrast overlay colors
      if (luminanceAccent < luminanceThreshold) {
        // On dark backgrounds - pure white
        newL = 1
        newC = 0
      } else {
        // On light backgrounds - pure black
        newL = 0
      }
      break
    }

    case "border-faded": {
      // Creates semi-transparent borders
      const adjustmentComputedFaded = contrastModifier * 3
      if (luminance < luminanceThreshold) {
        // Dark theme borders
        newO = 0.5 * adjustmentComputedFaded
        newL = 0.6
      } else {
        // Light theme borders
        newO = 0.5 * adjustmentComputedFaded
        newL = 0.7
      }
      break
    }

    default:
      break
  }

  // Ensure values stay within valid ranges
  if (newL >= 1) {
    newL = 1
    newC = 0
  }
  if (newL <= 0) {
    newL = 0
    newC = 0
  }
  if (!newH) newH = 0

  // Return the final color in OKLCH format with opacity
  return `oklch(${newL} ${newC} ${newH} / ${newO})`
}
